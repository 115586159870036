import React from 'react'
import SliderHome from './Slider'
function TopSec() {
  return (
    <div className=' pt-24 sm:pt-56 px-4 md:px-28 bg '>
       <SliderHome/>
    </div>
    
  )
}

export default TopSec